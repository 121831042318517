import { Box, BoxProps, Typography } from '@mui/material';

import {
  AltcoinistWithoutBgIcon,
  EthIcon,
  TelegramRoundedIcon,
} from '../../components/icons';

export function InformationalSidePanel({ ...props }: BoxProps) {
  return (
    <Box {...props}>
      <Typography fontSize={{ xs: '20px', md: '24px' }} fontWeight={500}>
        Monetize Onchain{' '}
        <Box component="br" display={{ xs: 'none', md: 'block' }} />
        with Altcoinist!
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        pt={{ xs: 5, md: 10 }}
        gap={{ xs: 4, md: 5 }}
      >
        <TelegramRoundedIcon />
        <Box
          gap={{ xs: 0, md: 2 }}
          display="flex"
          flexDirection="column"
          width="324px"
        >
          <Typography fontSize={{ xs: '16px', md: '20x' }}>
            Launch a Private Community
          </Typography>
          <Typography fontSize={'14px'} color={'#ACAFB3'} fontWeight={300}>
            Launch or integrate a private community and monetize it with
            Altcoinist.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        pt={{ xs: 5, md: 8 }}
        gap={{ xs: 4, md: 5 }}
      >
        <EthIcon stroke="#38FF93" size={24} />
        <Box
          gap={{ xs: 0, md: 2 }}
          display="flex"
          flexDirection="column"
          width="324px"
        >
          <Typography fontSize={{ xs: '16px', md: '20x' }}>
            Monetize Onchain
          </Typography>
          <Typography fontSize={'14px'} color={'#ACAFB3'} fontWeight={300}>
            Set up your pricing and earn recurring revenue through onchain
            subscriptions.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        pt={{ xs: 5, md: 8 }}
        gap={{ xs: 4, md: 5 }}
      >
        <AltcoinistWithoutBgIcon />
        <Box
          gap={{ xs: 0, md: 2 }}
          display="flex"
          flexDirection="column"
          width="324px"
        >
          <Typography fontSize={{ xs: '16px', md: '20x' }}>
            Share Revenue
          </Typography>
          <Typography fontSize={'14px'} color={'#ACAFB3'} fontWeight={300}>
            Share revenue with your members through your community staking pool
            and grow your brand quicker.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
