import { useEffect, useState } from 'react';

import { Box, Divider, Typography, useTheme } from '@mui/material';

import { useSetReferredMutation } from '../../api/users/mutations/useSetReferredMutation';
import { useSetUserTypeMutation } from '../../api/users/mutations/useSetUserTypeMutation';
import { useGeolocation } from '../../api/users/useGeolocation';
import { GlobalLoading } from '../../components/GlobalLoading';
import { InfoIcon } from '../../components/icons';
import { LogoWithText } from '../../components/LogoWithText';
import { RefCountdownSection } from '../../components/RefCountdownSection';
import UserTypeCard from '../../components/shared/Cards/UserTypeCard';
import { TwitterNotConnectedStep } from '../../components/signin/TwitterNotConnectedStep';
import { WalletConnectStep } from '../../components/signin/WalletConnectStep';
import { SignUpState } from '../../components/SignUpState';
import { TermsAndPolicyTypography } from '../../components/TermsAndPolicyTypography';
import { APP_PATH } from '../../constants/routings';
import useCurrentUserData from '../../hooks/auth/useCurrentUserData';
import useSignInStep from '../../hooks/auth/useSignInStep';
import { usePostHogCapture } from '../../hooks/usePostHogCapture';
import { useReferSearchValue } from '../../hooks/useReferSearchValue';
import navigate from '../../lib/navigate';
import { CountdownProvider } from '../../providers/CountdownProvider';
import { usePageContext } from '../../renderer/usePageContext';
import { SignInSteps } from '../../types/SignInSteps';
import { SubscriptionPlanType } from '../../types/SubscriptionPlanType';
import { UserType } from '../../types/UserType';

import { InformationalSidePanel } from './InformationalSidePanel';
import { SelectionPlanSidePanel } from './SelectionPlanSidePanel';

export function Page() {
  const theme = useTheme();
  const { data: geoData, isLoading: isLoadingGeoData } = useGeolocation();

  const pageContext = usePageContext();
  const userIdInUrl = pageContext.urlParsed?.search?.userId;

  const [planSelected, setPlanSelected] = useState<SubscriptionPlanType>(
    SubscriptionPlanType.MONTHLY,
  );
  const { data: userData } = useCurrentUserData();
  const signInStep = useSignInStep();
  const isLoadingStep =
    signInStep === SignInSteps.UserDataLoading ||
    signInStep === SignInSteps.SignupCompleted;

  const [isLoading, setIsLoading] = useState(isLoadingStep);

  const setUserTypeMutation = useSetUserTypeMutation();
  const isSuccessUserTypeMutation = setUserTypeMutation.isSuccess;

  const handleSetUserType = (userType: UserType) => {
    setIsLoading(true);
    setUserTypeMutation.mutate({ userId: userData.id, userType });
  };

  const referrerCode = useReferSearchValue();
  const setReferredMutation = useSetReferredMutation();
  const { captureOnSignUpComplete, captureSetupBasicProfile } = usePostHogCapture();

  useEffect(() => {
    if (isSuccessUserTypeMutation) {
      captureOnSignUpComplete(setUserTypeMutation.variables.userType);
      captureSetupBasicProfile(setUserTypeMutation.variables.userType);

      if (referrerCode) {
        setReferredMutation.mutate(referrerCode);
      }
      navigate(APP_PATH);
    }
  }, [isSuccessUserTypeMutation]);

  useEffect(() => {
    if (signInStep === SignInSteps.SignupCompleted) {
      navigate(APP_PATH);
    }
  }, [signInStep]);

  const handlePlanChange = () => {
    setPlanSelected(
      planSelected === SubscriptionPlanType.MONTHLY
        ? SubscriptionPlanType.LIFETIME
        : SubscriptionPlanType.MONTHLY,
    );
  };

  const isUsaUser = geoData?.isBlocked ?? false;

  if (isLoading || isLoadingGeoData) {
    return <GlobalLoading />;
  }

  return (
    <Box
      sx={{
        width: '100vw',
        height: { xs: 'auto', md: '100vh' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: { xs: 'none', md: '52px' },
        px: { xs: 5, md: 0 },
        pb: { xs: 10, md: 0 },
      }}
    >
      <Box
        sx={{
          display: { md: 'none', xs: 'block' },
          pt: 4,
          pb: 6,
          alignSelf: 'start',
        }}
      >
        <LogoWithText width={150} height={30} />
      </Box>
      <CountdownProvider>
        <RefCountdownSection
          minWidth={{ xs: '350px', md: '524px' }}
          alignItems={{ xs: 'start', md: 'center' }}
        />
      </CountdownProvider>
      {signInStep === SignInSteps.TwitterNotConnected && (
        <InformationalSidePanel
          sx={{
            display: { md: 'none', xs: 'block' },
            pt: { xs: 5, md: 10 },
          }}
        />
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          px: { xs: 0, md: 14 },
          maxWidth: '960px',
          border: { xs: 'none', md: '1px solid #2D2F33' },
          borderRadius: 3,
          justifyContent: 'center',
          bgcolor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            width: { xs: 'auto', md: '368px' },
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            py: { xs: 0, md: 10 },
          }}
        >
          <>
            <Box
              sx={{
                display: { md: 'block', xs: 'none' },
              }}
            >
              <LogoWithText />
            </Box>
            {signInStep === SignInSteps.TwitterNotConnected && (
              // Step 1: User has not connected their Twitter account
              <TwitterNotConnectedStep />
            )}

            <SignUpState step={signInStep} userIdInUrl={userIdInUrl} />

            {signInStep === SignInSteps.WalletNotConnected && (
              // Step 2: User needs to connect their wallet
              <WalletConnectStep />
            )}

            {signInStep === SignInSteps.UserWithoutType && !userIdInUrl && (
              // Step 3: User has connected Twitter but has not selected a user type  (Normal signup flow)
              <Box
                pt={{ xs: 14 }}
                display="flex"
                flexDirection="column"
                gap={4}
              >
                <UserTypeCard
                  title="I’m a creator"
                  description="I want to setup and grow my community with my followers."
                  action={() => handleSetUserType(UserType.CREATOR)}
                  isUsaUser={isUsaUser}
                />
                <UserTypeCard
                  title="I’m a follower"
                  description="I want to access my favourite creator’s content."
                  action={() => handleSetUserType(UserType.FOLLOWER)}
                />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={2}
                >
                  <InfoIcon />
                  <Typography
                    variant="body2"
                    fontWeight={300}
                    color={'#ACAFB3'}
                  >
                    As a follower, you can become a creator later.
                  </Typography>
                </Box>

                <TermsAndPolicyTypography pt={4} />
              </Box>
            )}
          </>
        </Box>

        <Divider
          orientation="vertical"
          flexItem
          sx={{ display: { md: 'block', xs: 'none' }, marginX: '32px' }}
        />

        <Box
          sx={{
            flexDirection: 'column',
            width: { xs: 'auto', md: '368px' },
            alignSelf: 'start',
            gap: 5,
            py: { xs: 0, md: 10 },
            display: { md: 'block', xs: 'none' },
          }}
        >
          {userIdInUrl ? (
            // Plan Selection Side Panel for referred users
            <SelectionPlanSidePanel
              planSelected={planSelected}
              handlePlanChange={handlePlanChange}
              username={userIdInUrl}
            />
          ) : (
            // Informational Side Panel for normal signup users (without referral)
            <InformationalSidePanel />
          )}
        </Box>
      </Box>
    </Box>
  );
}
